<template lang="html">
  <section class="v-home-marcas">
    <Toolbar class="p-mb-4 col-12">
      <template #left>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          class="p-button-success p-mr-2"
          @click="
            marca = {
              xnombre: '',
              xvisible: false,
              ximg: '',
              xcontrato_permiso: '',
              xto: '',
              xorden: 0,
            };
            marcaDialog = true;
          "
        />
        <Button
          label="Borrar"
          icon="pi pi-trash"
          class="p-button-danger ml-2"
          :disabled="!selectedMarcas || !selectedMarcas.length"
          @click="borrarMarcas"
        />
      </template>
      <template #right> </template>
    </Toolbar>
    <DataTable
      :value="marcas"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      :resizableColumns="true"
      columnResizeMode="fit"
      dataKey="xid"
      :rowHover="true"
      :selection.sync="selectedMarcas"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    >
      <template #header>
        <div class="table-header">
          Lista de marcas
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              v-model="filters['global']"
              placeholder="Búsqueda global"
            />
          </span>
        </div>
      </template>
      <!--XID COLUMN-->
      <Column field="xid" header="Id" :sortable="true">
        <template #body="slotProps">
          {{ slotProps.data.xmarcaid }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters['xmarcaid']"
            class="p-column-filter"
            placeholder="ID"
            style="width: 5em !important"
          />
        </template>
      </Column>
      <!--XIMG COLUMN-->
      <Column field="ximg" header="Img">
        <template #body="slotProps">
          <img
            style="max-height: 100px"
            :src="slotProps.data.ximg"
            @error="imagenError($event, slotProps)"
          />
        </template>
      </Column>
      <!--XNOMBRE COLUMN-->
      <Column field="xnombre" header="Nombre" :sortable="true">
        <template #body="slotProps">
          {{ slotProps.data.xnombre }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters['xnombre']"
            class="p-column-filter"
            placeholder="Nombre"
            style="width: 5em !important"
          />
        </template>
      </Column>
      <!--XTO COLUMN-->
      <Column field="xto" header="Enlace" :sortable="true">
        <template #body="slotProps">
          {{ slotProps.data.xto }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters['xto']"
            class="p-column-filter"
            placeholder="Enlace"
            style="width: 5em !important"
          />
        </template>
      </Column>
      <!--XVISIBLE COLUMN-->
      <Column field="xvisible" header="Visible" sortable>
        <template #body="slotProps">
          <vs-switch v-model="slotProps.data.xvisible" class="w-50" disabled />
        </template>
      </Column>
      <!--XORDEN COLUMN-->
      <Column field="xorden" header="Orden" :sortable="true">
        <template #body="slotProps">
          {{ slotProps.data.xorden }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters['xorden']"
            class="p-column-filter"
            placeholder="Orden"
            style="width: 5em !important"
          />
        </template>
      </Column>
      <!--BOTONES COLUMN-->
      <Column
        headerStyle="width: 4rem; text-align: center"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <vs-button
            icon
            @click="
              marca = slotProps.data;
              marcaDialog = true;
            "
          >
            <i class="pi pi-cog"></i>
          </vs-button>
          <vs-button
            icon
            color="danger"
            @click="
              borrarDialog = true;
              selectedMarcas = [slotProps.data];
            "
          >
            <i class="pi pi-trash"></i>
          </vs-button>
        </template>
      </Column>
      <Dialog
        :visible.sync="borrarDialog"
        :style="{ width: '450px' }"
        header="Confirmar"
        :modal="true"
      >
        <div class="d-flex flex-column">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span> ¿Seguro que quieres borrar estas marcas? </span>
          <ul>
            <li v-for="marca in selectedMarcas" :key="marca.xmarcaid">
              {{ marca.xmarcaid }} - {{ marca.xnombre }}
            </li>
          </ul>
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text text-white"
            @click="borrarDialog = false"
          />
          <Button
            label="Sí"
            icon="pi pi-check"
            class="p-button-danger"
            @click="borrarMarcas()"
          />
        </template>
      </Dialog>

      <Dialog
        :visible.sync="marcaDialog"
        :header="'Marca ' + marca.xnombre"
        :modal="true"
        class="p-fluid col-10"
        :baseZIndex="21001"
      >
        <div
          @submit.prevent=""
          class="
            p-fluid
            d-flex
            flex-wrap
            ml-auto
            mr-auto
            p-1
            col-12 col-lg-8
            justify-content-between
            align-content-between
          "
        >
          <div class="col-12 d-flex flex-wrap justify-content-center col-md-6">
            <vs-card>
              <template #img>
                <img
                  :src="marca.ximg"
                  @error="imagenError($event, marca)"
                  class="product-image"
                  style="max-height: 300px"
                  alt=""
                />
              </template>
              <template #interactions>
                <vs-button icon floating @click="uploadImg">
                  <i class="pi pi-images"></i>
                </vs-button>
                <vs-button icon floating @click="uploadContrato">
                  <i class="pi pi-file-pdf"></i>
                </vs-button>
              </template>
            </vs-card>
            <vs-tooltip bottom v-if="!marca.xcontrato_permiso">
              <vs-button danger disabled block class="mt-2">
                <span class="text-md d-flex align-items-center">
                  <i class="pi pi-ban mr-2"></i>Descargar permiso
                </span>
              </vs-button>
              <template #tooltip> No hay permisos subidos </template>
            </vs-tooltip>
            <vs-button
              primary
              class="mt-2 col-9"
              v-if="marca.xcontrato_permiso"
              @click="descargarContrato"
            >
              <span class="text-md d-flex align-items-center">
                <i class="pi pi-cloud-download mr-2"></i>Descargar permiso
              </span>
            </vs-button>
            <vs-tooltip class="col-2 " bottom v-if="marca.xcontrato_permiso">
              <vs-button
                danger
                class=""
                v-if="marca.xcontrato_permiso"
                @click="eliminarContrato"
              >
                <span class="text-md d-flex align-items-center">
                  <i class="pi pi-times"></i>
                </span>
              </vs-button>
              <template #tooltip> Eliminar permiso </template>
            </vs-tooltip>

            <input
              type="file"
              hidden
              accept="image/*"
              size="2000000"
              @change="cambiarImg"
              ref="uploadImg"
            />
            <input
              type="file"
              hidden
              accept="application/pdf"
              size="20000000"
              @change="cambiarPDF"
              ref="uploadPDF"
            />
          </div>
          <div class="col-12 col-md-6">
            <div class="p-field col-12">
              <label for="nombre">Nombre</label>
              <InputText id="nombre" type="text" v-model="marca.xnombre" />
            </div>
            <div class="p-field p-col-12">
              <label for="enlace">Enlace</label>
              <InputText id="enlace" type="text" v-model="marca.xto" />
            </div>
            <div class="col-12 d-flex align-items-center">
              <div class="p-field col-7">
                <label for="orden">Orden</label>
                <InputNumber id="orden" type="text" v-model="marca.xorden" />
              </div>
              <div class="p-field col-3">
                <label>Visible</label>
                <vs-switch v-model="marca.xvisible" />
              </div>
            </div>
          </div>
        </div>
        <template #footer>
          <Button
            label="Cancel"
            icon="pi pi-times"
            class="p-button"
            @click="marcaDialog = false"
          />
          <Button
            label="Save"
            icon="pi pi-check"
            class="p-button"
            @click="actualizarMarca"
          />
        </template>
      </Dialog>
    </DataTable>
  </section>
</template>

<script lang="js">
import API_URL from"../../backend.js"
import MarcasService from '../../services/marcas.service';
  export default  {
    name: 'v-home-marcas',
    props: [],
    mounted () {
      this.cargarMarcas();
    },
    data () {
      return {
        marcas:[],
        marca:{},
        selectedMarcas:[],
        filters: {},
        marcaDialog:false,
        borrarDialog:false,
      }
    },
    methods: {
      descargarContrato(){
        if(this.marca.xcontrato_permiso.includes("blob")){
          const link = document.createElement('a');
          link.href = this.marca.xcontrato_permiso;
          link.setAttribute('download', this.marca.xnombre ? this.marca.xnombre : "contrato" +'.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          return;
        }
        MarcasService.descargarContrato(this.marca.xmarcaid).then((response)=>{
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.marca.xnombre ? this.marca.xnombre : "contrato" +'.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }).catch((error) => {
          this.$toast.add({
            severity: 'error',
            summary:'Error',
            detail: error.message
          })
        })
      },
      eliminarContrato(){
        if(this.marca.xcontrato_permiso.includes("blob")){
          this.marca.xcontrato_permiso = "";
          this.marca.subirContrato = null;
          return;
        }
        MarcasService.borrarContrato(this.marca.xmarcaid).then((response)=>{
          this.$toast.add({
            severity:'info',
            summary:'Contrato eliminado',
            detail: response.data.description,
            life:2000
          })
          this.marca.xcontrato_permiso = "";
        }).catch((error) => {
          this.$toast.add({
            severity: 'error',
            summary:'Error',
            detail: error.message
          })
        })
      },
      uploadContrato(){
        this.$refs.uploadPDF.click();
      },
      uploadImg(){
        this.$refs.uploadImg.click();
      },
      cambiarPDF(){
        const [file] = this.$refs.uploadPDF.files;
        if(file.size>5000000){//5MB
          this.$toast.add({
            severity:'error',
            summary:'Tamaño del archivo',
            detail:'El PDF no puede pesar más de 5MB',
            life:10000
          });
          return;
        }
        if(!file.type.includes("application/pdf")){
          this.$toast.add({
            severity:'error',
            summary:'Tipo de archivo',
            detail:'El archivo debe ser de tipo PDF',
            life:10000
          })
          return;
        }

        this.marca.xcontrato_permiso = URL.createObjectURL(file);
        this.marca.subirContrato = file;
      },
      cambiarImg(){
        const [file] = this.$refs.uploadImg.files;
        if(file.size>10000000){//10MB
          this.$toast.add({
            severity:'error',
            summary:'Tamaño del archivo',
            detail:'La imagen no puede pesar más de 10MB',
            life:10000
          })
          return;
        }
        if(!file.type.includes("image/")){
          this.$toast.add({
            severity:'error',
            summary:'Tipo de archivo',
            detail:'El archivo debe ser de tipo imagen',
            life:10000
          })
          return;
        }

        this.marca.ximg = URL.createObjectURL(file);
        this.marca.subirImg = file;

      },
      imagenError(event){
        event.target.src = "/images/fondo_gris.jpg"
      },
      cargarMarcas(){
        MarcasService.getTodas().then((response)=>{
          this.marcas = response.data;
          this.marcas.forEach((marca) => {
            marca.ximg= API_URL+marca.ximg
            marca.xvisible = marca.xvisible == 1 ? true : false
          })
        }).catch((error)=>{
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: error.message
          })
        });
      },
      borrarMarcas(){
        var arrayPromises = [];
        for(let i = 0; i < this.selectedMarcas.length; i++){
          arrayPromises.push(MarcasService.borrar(this.selectedMarcas[i].xmarcaid))
        }
        Promise.all(arrayPromises).then((response)=>{
          for(let i = 0; i < response.length; i++){
             this.$toast.add({
              severity: response[i].data.success ? 'info' : 'error',
              summary: response[i].data.success ? 'Eliminado': 'Error',
              detail: response[i].data.description,
              life: response[i].data.success ? 1000 : null
            })
          }
          this.borrarDialog = false;
          this.cargarMarcas();
          this.selectedMarcas.length = 0;
        }).catch((error)=>{
          this.$toast.add({
            severity: 'error',
            summary:'Error',
            detail: error
          })
        });
      },
      actualizarMarca(){
        const formData = new FormData();
        formData.append("xnombre", this.marca.xnombre);
        formData.append("xvisible", this.marca.xvisible);
        formData.append("ximg", this.marca.ximg);
        formData.append("xto", this.marca.xto);
        formData.append("xorden", this.marca.xorden);
        formData.append("xcontrato_permiso", this.marca.xcontrato_permiso);
        if(this.marca.subirImg){
          formData.append("subirImg", this.marca.subirImg);
        }
        if(this.marca.subirContrato){
          formData.append("subirContrato", this.marca.subirContrato);
        }
        if(this.marca.xmarcaid){
          formData.append("xmarcaid", this.marca.xmarcaid);
          MarcasService.actualizar(formData).then((response)=>{
          this.$toast.add({
            severity: response.data.success ? 'info' : 'error',
            summary: response.data.success ? 'Actualizado': 'Error',
            detail: response.data.description,
            life: response.data.success ? 3000 : null
          })
          this.marcaDialog = false;
          this.cargarMarcas();
        }).catch((error)=>{
          this.$toast.add({
            severity: 'error',
            summary:'Error',
            detail: error
          })
        });
        }else{
          MarcasService.nuevo(formData).then((response)=>{
          this.$toast.add({
            severity: response.data.success ? 'info' : 'error',
            summary: response.data.success ? 'Creado': 'Error',
            detail: response.data.description,
            life: response.data.success ? 3000 : null
          })
          this.marcaDialog = false;
          this.cargarMarcas();
        }).catch((error)=>{
          this.$toast.add({
            severity: 'error',
            summary:'Error',
            detail: error
          })
        });
        }


      }
    },
    computed: {

    }
}
</script>

<style scoped lang="scss">
.v-home-marcas {
}
</style>
