import axios from 'axios';
import API_URL from '../backend'
import authHeader from './auth-header.service';

class MarcasService {
    getTodas(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/marcas/todas',
            headers: authHeader()            
        }
        return axios(axiosRequest);
    }
    nuevo(marca){
        const axiosRequest = {
            method: 'post',
            url: API_URL+'/admin/marcas/nuevo',
            data: marca,
            headers: authHeader()            
        }
        return axios(axiosRequest);
    }
    actualizar(marca){
        const axiosRequest = {
            method: 'post',
            url: API_URL+'/admin/marcas/update',
            data: marca,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    borrar(marcaId){
        const axiosRequest = {
            method: 'delete',
            url: API_URL+'/admin/marcas/borrar/'+marcaId,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    descargarContrato(marcaId){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/marcas/descargarContrato/'+marcaId,
            headers: authHeader(),
            responseType: 'blob'
        }
        return axios(axiosRequest);
    }
    borrarContrato(marcaId){
        const axiosRequest = {
            method: 'delete',
            url: API_URL+'/admin/marcas/borrarContrato/'+marcaId,
            headers: authHeader(),
            responseType: 'blob'
        }
        return axios(axiosRequest);
    }
}
export default new MarcasService();